class ZIDXAccountPayment implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountPaymentContainer";
    }
    getMatchURL(){
        return "/account/payment";
    }
    render(){
        // console.log("payment");
        ZIDX.$("#defaultCardButton").on("click", function(e:Event) {
            e.preventDefault();
            ZIDX.$(".zidxAccountPaymentContainer #paymentAction").val(1);
            ZIDX.$(".zidxAccountPaymentContainer .zidxForm").trigger("submit");
        });
        ZIDX.$("#deleteCardButton").on("click", function(e:Event) {
            e.preventDefault();
            ZIDX.$(".zidxAccountPaymentContainer #paymentAction").val(2);
            ZIDX.$(".zidxAccountPaymentContainer .zidxForm").trigger("submit");
        });
    }
}